import loader from '../../Assets/Loader.gif';

export const Loader = () => {
  return (
    <img
      src={loader}
      alt='loader gif'
      loading='lazy'
      className='max-w-[60%] md:max-w-[30%]'
    />
  );
};

export default Loader;
