import React, { useState, useEffect } from 'react';
import { ThemeOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';

import './index.scss';
import reportWebVitals from './reportWebVitals';
import AppRouter from './AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import { login, logout } from './Redux/auth/authReducer';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Loader from './Component/Loader/Loader';
import * as ReactDOM from 'react-dom/client';

const firebaseConfig = {
  apiKey: 'AIzaSyCpxSeMTcT-9A3PTwFLxS1mg7XvTJTTV4I',
  authDomain: 'groove-graphs.firebaseapp.com',
  projectId: 'groove-graphs',
  storageBucket: 'groove-graphs.appspot.com',
  messagingSenderId: '568567551440',
  appId: '1:568567551440:web:67e94b9bc9939a899315d5',
  measurementId: 'G-7GE0MN7Z5B',
};

export const themeOptions: ThemeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#04a118',
    },
    secondary: {
      main: '#283240',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#2196f3',
    },
    success: {
      main: '#4caf50',
    },
  },
});

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// Enable session persistence
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserId(analytics, user.uid);
        // if (
        //   user.email !== "justinweekley7@gmail.com" &&
        //   user.email !== "joshuap.smith12@gmail.com"
        // ) {
        logEvent(analytics, 'app_load', {
          content_type: user.uid,
          user_email: user.email,
        });
        store.dispatch(login());
        // }
      } else {
        store.dispatch(logout());
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    // Show a loading spinner or placeholder component
    return (
      <div className='flex justify-center h-screen items-center'>
        <Loader />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeOptions}>
      <Provider store={store}>
        <Root />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
