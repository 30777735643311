import {
  DURATION,
  LIST_OF_ASSETS_ADDED,
  PREVIEW,
  ADD_ASSETS_TO_SEE_LIST,
  UPDATE_ASSETS,
  CLEAR_ALL,
} from "../../helper/constant";
import { previewAsset } from "../../helper/helper";
import { deleteAsset, emptyAsssestState } from "../../Redux/Reducer";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";

type ChartFooterType = {
  setAssetState: (asset: any) => void;
  setIsPreview: (isPreview: boolean) => void;
  setShow: (show: boolean) => void;
};

export const ChartFooter = (props: ChartFooterType) => {
  const totalAsset = useSelector((store: any) => store.asset);
  const dispatch = useDispatch();

  const theme = useTheme();

  // Access theme variables
  const secondaryColor = theme.palette.secondary.main;
  const primaryContrastText = theme.palette.primary.contrastText;

  return (
    <>
      <div className="modal-text">{DURATION}</div>
      <div className="flex justify-around my-6 text-white">
        <div
          className="w-3/4 rounded-md p-4 flex flex-col text-left"
          style={{ backgroundColor: secondaryColor }}
        >
          <div className="list-asset-added">
            <h4 className="asset_list_h4">Asset List</h4>
          </div>

          {totalAsset.length > 0 ? (
            totalAsset.map((asset: any) => {
              return (
                <div className="flex justify-between pt-4">
                  <div className="align-middle p-2">{`${asset.assetName}`}</div>
                  <div>
                    <Button
                      style={{ color: primaryContrastText }}
                      variant="text"
                      onClick={() =>
                        previewAsset(
                          asset,
                          props.setAssetState,
                          props.setIsPreview,
                          props.setShow
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      style={{ color: primaryContrastText }}
                      onClick={() => dispatch(deleteAsset(asset.id))}
                      className="delete_asset_btn"
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-between">
              <div>{ADD_ASSETS_TO_SEE_LIST}</div>
            </div>
          )}
        </div>
        <div className="footer-button-container">
          <div className="footer-buttons">
            <div className="mb-1">
              <Button
                variant="contained"
                color="primary"
                role="button"
                fullWidth
                onClick={() => {
                  props.setShow(true);
                  props.setAssetState(false);
                  props.setIsPreview(false);
                }}
              >
                Add Assets
              </Button>
            </div>

            {totalAsset.length > 0 && (
              <Button
                variant="contained"
                color="warning"
                type="button"
                fullWidth
                onClick={() => dispatch(emptyAsssestState())}
              >
                {CLEAR_ALL}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartFooter;
