import { configureStore } from "@reduxjs/toolkit";
import assetReducers from "./Reducer";
import authReducer from "./auth/authReducer";

const store = configureStore({
  reducer: {
    asset: assetReducers,
    auth: authReducer,
  },
});


// Define the type for the dispatch function
export type AppDispatch = typeof store.dispatch;

export default store;
