import React, { useState, ChangeEvent, FormEvent } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { logEvent } from "firebase/analytics";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../Redux/auth/authReducer";
import logo from "../../Assets/LogoRectangle/LogoRectangleGreen.png";
import { Button, FormHelperText, TextField } from "@mui/material";
import { analytics } from "../..";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      const user = firebase.auth().currentUser;
      // Login successful, you can redirect or perform additional actions here
      logEvent(analytics, "login", {
        content_type: user?.uid,
        user_email: email,
      });

      navigate("/");
      dispatch(login());
    } catch (error) {
      setError((error as firebase.FirebaseError).message);
    }
  };

  return (
    // <div className="flex items-center justify-center min-h-screen bg-gray-100">
    //   <div className="max-w-md w-full mx-4 bg-white rounded-lg shadow-lg p-6">
    //     <div className="flex justify-between items-center mb-6">
    //       <h2 className="text-3xl font-bold">Login</h2>
    //       <img
    //         src={logo}
    //         alt="logo"
    //         loading="lazy"
    //         className="max-w-[55%] h-1/2"
    //       />
    //     </div>
    //     <form onSubmit={handleSubmit}>
    //       <div className="mb-4">
    //         <label
    //           htmlFor="email"
    //           className="block text-gray-700 text-sm font-bold mb-2"
    //         >
    //           Email
    //         </label>
    //         <input
    //           type="email"
    //           id="email"
    //           className="border border-gray-300 rounded-md py-2 px-3 w-full"
    //           value={email}
    //           onChange={handleEmailChange}
    //           required
    //         />
    //       </div>
    //       <div className="mb-6">
    //         <label
    //           htmlFor="password"
    //           className="block text-gray-700 text-sm font-bold mb-2"
    //         >
    //           Password
    //         </label>
    //         <input
    //           type="password"
    //           id="password"
    //           className="border border-gray-300 rounded-md py-2 px-3 w-full"
    //           value={password}
    //           onChange={handlePasswordChange}
    //           required
    //         />
    //       </div>
    //       {error && <div className="text-red-500 mb-4">{error}</div>}
    //       <Button
    //         type="submit"
    //         className="py-2 px-4 rounded-md font-bold w-full hover:bg-blue-600"
    //       >
    //         Login
    //       </Button>
    //     </form>
    //     <div className="mt-4 text-sm text-center">
    //       <Link to="/reset-password" className="text-blue-500 underline">
    //         Forgot password?
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="max-w-sm w-full p-8 bg-white shadow-md rounded-md">
        <img src={logo} alt="Logo" className="mx-auto mb-6" />
        <form className="space-y-4" onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            required
            fullWidth
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required={true}
            fullWidth
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Sign In
          </Button>

          <p className="text-center mt-4">
            <Link to="/reset-password">Forgot Password?</Link>
          </p>
          {error && (
            <FormHelperText error={true} className="mt-2">
              {error}
            </FormHelperText>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
