import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./Component/ProtectedRoute/ProtectedRoute";
import Login from "./Component/Login/login";
import App from "./App";
import ResetPassword from "./Component/ResetPassword/reset-password";

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<App />} />
      </Route>
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;
