import React, { useState, useEffect } from "react";
import { checkAsset, resetInputs, previewAsset } from "../../helper/helper";
import {
  addAsset,
  emptyAsssestState,
  updateAssetReducer,
  deleteAsset,
} from "../../Redux/Reducer";
import {
  ASSET_NAME,
  ASSET_CLASS,
  RETIREMENT,
  ROTH,
  REAL_ESTATE_EQUITY,
  LIFE_INSURANCE,
  SAVINGS,
  INVESTMENTS,
  TAX_TREATMENT,
  TAX_DEFERRED,
  TAXABLE,
  TAX_FREE,
  DURATION,
  SHORT,
  INTERMEDIATE,
  LONG,
  AMOUNT_INVESTED,
  FIXED_INCOME,
  VIEW_GRAPH,
  CLEAR_ALL,
  LIST_OF_ASSETS_ADDED,
  PREVIEW,
  ADD_ASSETS_TO_SEE_LIST,
  UPDATE_ASSETS,
  FIELD_ERROR,
} from "../../helper/constant";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";

import "./AssetModal.scss";
import { useTheme } from "@mui/material";

type AssetModalType = {
  assetState: any;
  isPreview: boolean;
  show: boolean;
  setShow: (show: boolean) => void;
};

function AssetModal(props: AssetModalType) {
  const dispatch = useDispatch();

  const theme = useTheme();
  // Access theme variables
  const secondaryColor = theme.palette.secondary.main;

  //-----------intial inputs value----------//
  const intialAssetValue: {
    assetName: string;
    assetClass: string;
    taxTreatment: string;
    duration: string;
    amountInvested: string;
    fixedIncomeCheckbox: boolean;
    fixedIncome: number;
  } = {
    assetName: "",
    assetClass: "",
    taxTreatment: "",
    duration: "",
    amountInvested: "",
    fixedIncomeCheckbox: false,
    fixedIncome: 0,
  };

  //-------------------------------------------//
  //-------------state------------------------//
  //-------------------------------------------//

  const [show, setShow] = useState<boolean>(props.show || true);
  const [assetState, setAssetState] = useState<any>(
    props.assetState || intialAssetValue
  );

  const [isPreview, setIsPreview] = useState<boolean>(props.isPreview || false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const totalAsset = useSelector((store: any) => store.asset);

  //---------------------------------------------------------------------------------//
  //-------------functions related to asset submit and errors-----------------------//
  //---------------------------------------------------------------------------------//

  const getAssets = (e: any) => {
    const { value, name, checked } = e.target;
    setAssetState({
      ...assetState,
      [name]: name === "fixedIncomeCheckbox" ? checked : value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    if (isPreview) {
      const res = await checkAsset(assetState);
      if (res) {
        if (assetState.fixedIncome === "") {
          assetState.fixedIncome = 0;
        }
        dispatch(updateAssetReducer(assetState));
        setIsSubmit(false);
        resetInputs(setAssetState);
        setIsPreview(false);
      }
    } else {
      const res = await checkAsset(assetState);
      if (res) {
        assetState.id = Date.now();
        if (assetState.fixedIncome === "") {
          assetState.fixedIncome = 0;
        }
        dispatch(addAsset(assetState));
        setIsSubmit(false);
        setIsError(false);
        resetInputs(setAssetState);
      } else {
        setIsError(true);
      }
    }
  };

  function handleCancel() {
    resetInputs(setAssetState);
    setIsPreview(false);
    props.setShow(false);
    setIsSubmit(false);
  }

  function handleViewGraph() {
    resetInputs(setAssetState);
    props.setShow(false);
    setIsSubmit(false);
    setIsPreview(false);
  }

  useEffect(() => {
    props.setShow(true);
  }, []);

  return (
    <Modal open={props.show}>
      <Grid className="backdrop-blur-[2px] w-full h-full absolute flex justify-center p-[32px] items-center overflow-y-auto text-white">
        <div
          className="pt-[38px] px-[32px] mt-[380px] w-max h-max sm:m-[32px] sm:p-[32px] rounded-xl"
          style={{ backgroundColor: secondaryColor }}
        >
          <div className="flex justify-end">
            <Button variant="contained" onClick={() => handleCancel()}>
              <CloseIcon />
            </Button>
          </div>
          <div className="p-1 flex flex-col items-center">
            <form className="asset-form">
              <div className="asset-form-row">
                <div className="asset-form-section">
                  <label className="assetNamelabel">{ASSET_NAME}</label>
                  <input
                    type="text"
                    className="assetName_input"
                    value={assetState.assetName}
                    name="assetName"
                    onChange={(e) => getAssets(e)}
                    required
                  />
                  {isSubmit && assetState.assetName === "" ? (
                    <span className="field_error">{FIELD_ERROR}</span>
                  ) : null}
                </div>

                <div className="asset-form-section">
                  <label className="assetClass_label">
                    {ASSET_CLASS} <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="select_aseestclass"
                    value={assetState.assetClass}
                    name="assetClass"
                    onChange={(e) => getAssets(e)}
                  >
                    <option value=""></option>
                    <option value="Retirement">{RETIREMENT}</option>
                    <option value="Roth">{ROTH}</option>
                    <option value="Real Estate Equity">
                      {REAL_ESTATE_EQUITY}
                    </option>
                    <option value="Life Insurance">{LIFE_INSURANCE}</option>
                    <option value="Savings">{SAVINGS}</option>
                    <option value="Investments">{INVESTMENTS}</option>
                  </select>
                  {isSubmit && assetState.assetClass === "" ? (
                    <span className="field_error">{FIELD_ERROR}</span>
                  ) : null}
                </div>
              </div>
              <div className="asset-form-row">
                <div className="asset-form-section">
                  <label className="taxTreatment_label">
                    {TAX_TREATMENT} <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="select_taxTreatment"
                    value={assetState.taxTreatment}
                    name="taxTreatment"
                    onChange={(e) => getAssets(e)}
                  >
                    <option value=""></option>
                    <option value="tax-deferred">{TAX_DEFERRED}</option>
                    <option value="taxable">{TAXABLE}</option>
                    <option value="tax-free">{TAX_FREE}</option>
                  </select>
                  {isSubmit && assetState.taxTreatment === "" ? (
                    <span className="field_error">{FIELD_ERROR}</span>
                  ) : null}
                </div>
                <div className="asset-form-section">
                  <label className="duration_label">
                    {DURATION} <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="select_duration"
                    value={assetState.duration}
                    name="duration"
                    onChange={(e) => getAssets(e)}
                  >
                    <option value=""></option>
                    <option value="Short">{SHORT}</option>
                    <option value="Intermediate">{INTERMEDIATE}</option>
                    <option value="Long">{LONG}</option>
                  </select>
                  {isSubmit && assetState.duration === "" ? (
                    <span className="field_error">{FIELD_ERROR}</span>
                  ) : null}
                </div>
              </div>

              <div className="asset-form-row">
                <div className="asset-form-section">
                  <label className="amountInvested_label">
                    {AMOUNT_INVESTED} <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="amountinvest_input"
                    value={assetState.amountInvested}
                    name="amountInvested"
                    onChange={(e) => getAssets(e)}
                  />
                  {isSubmit && assetState.amountInvested === "" ? (
                    <span className="field_error">{FIELD_ERROR}</span>
                  ) : null}
                </div>
                <div className="asset-form-section w-[280px]">
                  <div className="flex flex-col">
                    <label className="label_fixedIncome">{FIXED_INCOME}</label>
                    <input
                      type="checkbox"
                      className="checkbox"
                      onChange={(e) => getAssets(e)}
                      name="fixedIncomeCheckbox"
                      checked={assetState.fixedIncomeCheckbox}
                    />
                  </div>
                </div>
              </div>

              <div className="asset-form-row">
                <div>
                  {assetState.fixedIncomeCheckbox === true ? (
                    <div className="asset-form-section">
                      <label className="FixedIncome_label">
                        {FIXED_INCOME}
                      </label>
                      <input
                        type="number"
                        className="fixed_input"
                        name="fixedIncome"
                        value={assetState.fixedIncome}
                        onChange={(e) => getAssets(e)}
                      />
                      {assetState.fixedIncomeCheckbox === true &&
                      isSubmit &&
                      assetState.fixedIncome === "" ? (
                        <span className="field_error">{FIELD_ERROR}</span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
            <div className="flex justify-around w-full mt-6">
              <Button
                type="button"
                variant="outlined"
                color="warning"
                onClick={() => resetInputs(setAssetState)}
              >
                {CLEAR_ALL}
              </Button>
              <Button
                type="button"
                color="info"
                data-bs-dismiss="modal"
                aria-label="Close"
                variant="outlined"
                onClick={() => handleViewGraph()}
              >
                {VIEW_GRAPH}
              </Button>
              <Button
                type="submit"
                onClick={() => handleSubmit()}
                variant="outlined"
                color="primary"
              >
                {isPreview ? "Update Asset" : "Add Asset"}
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    </Modal>
  );
}

export default AssetModal;
