import React, { useEffect, useState } from "react";
import "./App.scss";
import Chart from "./Component/Chart/Chart";
import AssetModal from "./Component/AssetModal/AssetModal";
import store from "./Redux/Store";
import { Provider } from "react-redux";
import ChartFooter from "./Component/ChartFooter/ChartFooter";
import Header from "./Component/Header/header";

function App() {
  const [assetState, setAssetState] = useState<any>();
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  return (
    <div className="App">
      <Provider store={store}>
        <Header />
        <Chart />
        <ChartFooter
          setAssetState={setAssetState}
          setIsPreview={setIsPreview}
          setShow={setShow}
        />
        {show && (
          <AssetModal
            assetState={assetState}
            isPreview={isPreview}
            show={show}
            setShow={setShow}
          />
        )}
      </Provider>
    </div>
  );
}

export default App;
