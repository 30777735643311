import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import firebase from "firebase/compat/app";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/auth/authReducer";
import logo from "../../Assets/LogoRectangle/LogoRectangleWhite.png";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await firebase.auth().signOut();
      // Logout successful, you can perform additional actions here
      dispatch(logout());
    } catch (error) {
      console.log("Error signing out:", error);
    }

    handleMenuClose();
  };

  return (
    <AppBar position="fixed" style={{ zIndex: 9998 }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <img
            src={logo}
            alt="logo"
            loading="lazy"
            className="max-w-[75%] mr-auto my-[10px] md:max-w-[35%] lg:max-w-[25%]"
          />
        </Typography>
        <IconButton
          size="large"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ zIndex: 9999 }}
        >
          <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
