import { createSlice } from "@reduxjs/toolkit";

const assetReducers = createSlice({
  name: "asset",
  initialState: [],
  reducers: {
    addAsset(state, action) {
      state.push(action.payload);
    },
    emptyAsssestState(state) {
      state.length = 0;
    },
    updateAssetReducer(state, action) {
      const index = state.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...action.payload };
      }
    },
    deleteAsset(state, action) {
      return state.filter((asset) => asset.id !== action.payload);
    },
  },
});
export const { addAsset, emptyAsssestState, updateAssetReducer, deleteAsset } =
  assetReducers.actions;
export default assetReducers.reducer;
