import React, { useState, ChangeEvent, FormEvent } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Button, FormHelperText, TextField } from "@mui/material";

import logo from "../../Assets/LogoRectangle/LogoRectangleGreen.png";

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setSuccess(true);
    } catch (error) {
      setError((error as firebase.FirebaseError).message);
    }
  };

  return (
    // <div className="flex items-center justify-center min-h-screen bg-gray-100">
    //   <div className="max-w-md w-full mx-4 bg-white rounded-lg shadow-lg p-6">
    //     <h2 className="text-3xl font-bold mb-6">Reset Password</h2>
    //     {success ? (
    //       <p className="text-green-500 mb-4">
    //         Password reset email sent. Check your inbox.
    //       </p>
    //     ) : (
    //       <form onSubmit={handleSubmit}>
    //         <div className="mb-4">
    //           <label
    //             htmlFor="email"
    //             className="block text-gray-700 text-sm font-bold mb-2"
    //           >
    //             Email
    //           </label>
    //           <input
    //             type="email"
    //             id="email"
    //             className="border border-gray-300 rounded-md py-2 px-3 w-full"
    //             value={email}
    //             onChange={handleEmailChange}
    //             required
    //           />
    //         </div>
    //         {error && <div className="text-red-500 mb-4">{error}</div>}
    //         <button
    //           type="submit"
    //           className="bg-blue-500 text-white py-2 px-4 rounded-md font-bold w-full hover:bg-blue-600"
    //         >
    //           Reset Password
    //         </button>
    //       </form>
    //     )}
    //   </div>
    // </div>
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="max-w-sm w-full p-8 bg-white shadow-md rounded-md">
        <img src={logo} alt="Logo" className="mx-auto mb-6" />
        <h2 className="mb-4">Reset Password</h2>
        {success ? (
          <p className="mb-4">Password reset email sent. Check your inbox.</p>
        ) : (
          <form className="space-y-4" onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              required
              fullWidth
            />
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Reset Password
            </Button>
            {error && (
              <FormHelperText error={true} className="mt-2">
                {error}
              </FormHelperText>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
